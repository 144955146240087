import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLoading, useWebExtension } from "../context";
import { ApplyTo, GetCurrentAccountData, ItemType, LSKDiscount, LSKItem, Response } from "../types";
import { addItemToCurrentAccount } from "../modules/helpers";
import { getItemBySku, getOptionsForPromotion, getOptionsForReward } from "../api";
import * as Sentry from "@sentry/react";

export default function useTransactionHandler(errorHandler: Dispatch<SetStateAction<string>>) {
    const [getCurrentAccountData, setGetCurrentAccountData] = useState<GetCurrentAccountData>();
    const [currentDiscount, setCurrentDiscount] = useState<LSKDiscount>();
    const [activeOptions, setActiveOptions] = useState<Array<{ label: string, value: string }>>();
    const [allOptions, setAllOptions] = useState<Array<{ label: string, value: string }>>();

    const { setLoading } = useLoading();

    const { discounts } = useWebExtension();

    useEffect(() => {
        window.pos_getCurrentAccount(({ data }: Response<GetCurrentAccountData, any>) => {
            setGetCurrentAccountData(data);
        });
    }, []);

    const getTransactionLine = (itemIdentifier: string) =>
        getCurrentAccountData?.transactionLines?.find((line: any) => line.itemIdentifier === itemIdentifier);

    const handleItem = (item: LSKItem, discount: LSKDiscount) => {
        addItemToCurrentAccount(item, discount);
    };

    const handleSubItem = async (item: LSKItem, discount: LSKDiscount, onReverse?: (message: string) => void) => {
        const res = await getItemBySku(posContext.locationId, "LEAT-DISCOUNT-ITEM");
        const leatDiscountItem = res.data.item;

        if (leatDiscountItem) {
            const priceInCents = item.prices[0].amount * 100;
            let discountAmountInCents: number = 0;
            if (discount.discountAmount) {
                discountAmountInCents = parseFloat(discount.discountAmount) * 100;
            }
            if (discount.discountPercentage) {
                discountAmountInCents = priceInCents * (parseFloat(discount.discountPercentage) / 100);
            }

            window.pos_addSpecialItemToCurrentAccount(
                leatDiscountItem.id.toString(),
                discountAmountInCents,
                `Leat Discount Item: ${item.name} (${discount.name})`,
            );
        } else {
            Sentry.captureMessage("No item with sku 'LEAT-DISCOUNT-ITEM' configured.");
        }
    };

    const handleSelect = async ({ value }: { label: string, value: string }, callback: () => void) => {
        setLoading && setLoading(true);

        const { data: { item } } = await getItemBySku(posContext.locationId, value);
        const transactionLine = getTransactionLine(item.id.toString());
        if (item.itemType === ItemType.SUB_ITEM || (transactionLine && transactionLine.parentLine)) {
            await handleSubItem(item, currentDiscount!);
        } else if (item.itemType === ItemType.SEQUENCE && !transactionLine) {
            errorHandler("Can't add combo item rewards if item is not already in account.");
        } else {
            handleItem(item, currentDiscount!);
        }
        callback?.();

        setLoading && setLoading(false);
    }

    const handleTransactionLineItem = async (item: any, discount: LSKDiscount) => {
        if (item.parentLine) {
            const res = await getItemBySku(posContext.locationId, "LEAT-DISCOUNT-ITEM");
            const leatDiscountItem = res.data.item;

            if (leatDiscountItem) {
                const priceInCents = item.amount;
                let discountAmountInCents: number = 0;
                if (discount.discountAmount) {
                    discountAmountInCents = parseFloat(discount.discountAmount) * 100;
                }
                if (discount.discountPercentage) {
                    discountAmountInCents = priceInCents * (parseFloat(discount.discountPercentage) / 100);
                }

                window.pos_addSpecialItemToCurrentAccount(
                    leatDiscountItem.id.toString(),
                    discountAmountInCents,
                    `Leat Discount Item: ${item.text} (${discount.name})`,
                );
            }
        } else {
            window.pos_toggleDiscount(discount.code, item.identifier, (response: any) => {
                if (response.error && response.error.message) {
                    Sentry.captureMessage(response.error.message);
                }
            });
        }
    }

    const singleItemReward = async (itemSku: string, discount: LSKDiscount, type: "REWARD" | "VOUCHER") => {
        setLoading && setLoading(true);
        const transactionLine = getTransactionLineItemBySku(itemSku);

        if (transactionLine) {
            await handleTransactionLineItem(transactionLine, discount);
        } else {
            const { data: { item } } = await getItemBySku(posContext.locationId, itemSku);
            if (item.itemType === ItemType.SUB_ITEM) {
                await handleSubItem(item, discount);
            } else if (item.itemType === ItemType.SEQUENCE && !transactionLine) {
                errorHandler("Can't add combo item rewards if item is not already in account.");
            } else {
                handleItem(item, discount);
            }
        }

        setLoading && setLoading(false);
    }

    const multiItemReward = async (itemSkus: Array<string>, discount: LSKDiscount, type: "REWARD" | "VOUCHER", uuid: string, onMultiItem?: () => void) => {
        const transactionLines = itemSkus.map(sku => getTransactionLineItemBySku(sku)).filter(l => l);

        if (transactionLines.length === 1) {
            return handleTransactionLineItem(transactionLines[0], discount);
        }

        let caOptions: Array<{ label: string, value: string }> = [];
        if (type === "VOUCHER") {
            const res = await getOptionsForPromotion(uuid);
            caOptions = res.data;
        } else {
            const res = await getOptionsForReward(uuid);
            caOptions = res.data;
        }

        setAllOptions(caOptions);

        if (transactionLines.length && transactionLines.length > 1) {
            setActiveOptions(caOptions.filter(option => transactionLines.find((line) => line?.itemSku === option.value)));
        }

        onMultiItem?.();
    }

    const getTransactionLineItemBySku = (sku: string) =>
        getCurrentAccountData?.transactionLines.find((line) => line.itemSku === sku && !line.discounts.length);

    const handle = async (
        uuid: string,
        lskItems: string,
        discount: string,
        applyTo: ApplyTo,
        onReverse: (message: string) => void,
        type: "REWARD" | "VOUCHER",
        onMultiItem?: () => void,
    ) => {
        const discountObj = discounts.find((d) => d.code === discount);
        setCurrentDiscount(discountObj);

        if (lskItems && discountObj) {
            const lskSkus = (lskItems as string).split(";");

            if (lskSkus.length > 1) {
                await multiItemReward(lskSkus, discountObj, type, uuid, onMultiItem);
            } else {
                await singleItemReward(lskSkus[0], discountObj, type);
            }
        }

        if (applyTo === ApplyTo.ORDER && getCurrentAccountData?.totalAmount) {
            window.pos_toggleGlobalDiscount(discount, (response: any) => {
                if (response.error && response.error.message) {
                    Sentry.captureMessage(response.error.message);
                }
            });
        }
    };

    return {
        handle,
        handleSelect,
        activeOptions,
        allOptions,
        currentDiscount,
    };
}
