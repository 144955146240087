import { useEffect, useState } from "react";

type ItemSelectProps = {
    options: Array<{ label: string, value: string }>;
    activeOptions?: Array<{ label: string, value: string }>;
    onItemSelect: (option: { label: string, value: string }) => void;
};
export default function ItemSelect({ options, activeOptions, onItemSelect }: ItemSelectProps) {
    const [page, setPage] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [active, setActive] = useState<"order" | "all">(activeOptions && activeOptions.length ? "order" : "all");

    useEffect(() => {
        if (activeOptions && activeOptions.length) {
            setActive("order");
            setPage(1);
        }
    }, [activeOptions]);
    const filteredItems = () =>
        (active === "order" ? activeOptions ?? [] : options).filter((option) =>
            option.label.toLowerCase().includes(searchQuery.toLowerCase()),
        );

    const getItems = () => {
        const filtered = filteredItems();
        const start = page === 1 ? 0 : 10 * (page - 1);
        return filtered.slice(start, start + 10);
    };

    const getNumberOfPages = () => Math.ceil(filteredItems().length / 10);

    return (
        <div className="select-none">
            <div className="mb-4 border-b border-b-gray-300 pb-4">
                <input
                    type="text"
                    id="search"
                    className="block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-gray-500 focus:ring-gray-500"
                    placeholder={`Search...`}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setPage(1);
                    }}
                />
            </div>

            {activeOptions && !!activeOptions.length && (
                <div className="mb-4 flex rounded-lg border-gray-900 text-center text-sm font-medium text-gray-500">
                    <div className="w-full focus-within:z-10">
                        <button
                            onClick={() => {
                                setActive("order");
                                setPage(1);
                            }}
                            className={`inline-block w-full p-2 text-gray-900 ${active === "order" ? "bg-gray-100" : "bg-white"} active rounded-s-lg border border-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-300`}
                        >
                            Order items
                        </button>
                    </div>
                    <div className="w-full focus-within:z-10">
                        <button
                            onClick={() => {
                                setActive("all");
                                setPage(1);
                            }}
                            className={`inline-block w-full p-2 text-gray-900 ${active === "all" ? "bg-gray-100" : "bg-white"} active rounded-e-lg border border-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-300`}
                        >
                            All items
                        </button>
                    </div>
                </div>
            )}

            <div className={`mb-4 grid grid-cols-2 gap-x-2 gap-y-2 border-b border-b-gray-300 pb-4`}>
                {getItems().map((item) => (
                    <button
                        className="h-10 w-56 truncate rounded-lg border border-gray-300 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100"
                        key={item.value}
                        onClick={() => onItemSelect(item)}
                    >
                        {item.label}
                    </button>
                ))}
            </div>

            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{page === 1 ? 1 : (page - 1) * 10 + 1}</span> to{" "}
                        <span className="font-medium">{Math.min(page * 10, filteredItems().length)}</span> of{" "}
                        <span className="font-medium">{filteredItems().length}</span> items
                    </p>
                </div>

                <div>
                    <div className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                        <div
                            onClick={() => page > 1 && setPage(page - 1)}
                            className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${page <= 1 ? "cursor-not-allowed" : "cursor-pointer"}`}
                        >
                            <div className="flex items-center justify-center">{"<"}</div> {/* TODO: ICONS */}
                        </div>

                        {getNumberOfPages() > 4 ? (
                            <>
                                {[...Array(getNumberOfPages()).keys()]
                                    .slice(
                                        page <= 3
                                            ? 0
                                            : page === getNumberOfPages() - 3
                                                ? page - 3
                                                : page >= getNumberOfPages() - 2
                                                    ? 0
                                                    : page - 2,
                                        page <= 3
                                            ? 3
                                            : page === getNumberOfPages() - 3
                                                ? page
                                                : page >= getNumberOfPages() - 2
                                                    ? 1
                                                    : page + 1,
                                    )
                                    .map((pageNumber) => (
                                        <button
                                            onClick={() => setPage(pageNumber + 1)}
                                            key={pageNumber}
                                            className={`relative inline-flex w-8 items-center justify-center px-3 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${page === pageNumber + 1 && "bg-gray-100"}`}
                                        >
                                            {pageNumber + 1}
                                        </button>
                                    ))}

                                <div className="relative inline-flex w-8 items-center justify-center px-3 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                    ...
                                </div>

                                {[...Array(3).keys()].reverse().map((pageNumber) => (
                                    <button
                                        onClick={() => setPage(getNumberOfPages() - pageNumber)}
                                        key={pageNumber}
                                        className={`relative inline-flex w-8 items-center justify-center px-3 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${page === getNumberOfPages() - pageNumber && "bg-gray-100"}`}
                                    >
                                        {getNumberOfPages() - pageNumber}
                                    </button>
                                ))}
                            </>
                        ) : (
                            [...Array(getNumberOfPages()).keys()].map((pageNumber) => (
                                <button
                                    onClick={() => setPage(pageNumber + 1)}
                                    key={pageNumber}
                                    className={`relative inline-flex w-8 items-center justify-center px-3 py-1 text-xs text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${page === pageNumber + 1 && "bg-gray-100"}`}
                                >
                                    {pageNumber + 1}
                                </button>
                            ))
                        )}

                        <div
                            onClick={() => page < getNumberOfPages() && setPage(page + 1)}
                            className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${page >= getNumberOfPages() ? "cursor-not-allowed" : "cursor-pointer"}`}
                        >
                            <div className="flex items-center justify-center">{">"}</div> {/* TODO: ICONS */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
