import { createContext, ReactNode, useContext } from "react";
import { IntegrationConfiguration, LSKDiscount } from "../types";

export type WebExtensionContextType = {
    apiKey: string;
    configuration: IntegrationConfiguration;
    discounts: Array<LSKDiscount>,
    contactUuid?: string,
};

export const WebExtensionContext = createContext<WebExtensionContextType | null>(null);

const WebExtensionProvider = ({ children, apiKey, configuration, discounts, contactUuid }: {
    children: ReactNode;
} & WebExtensionContextType) => {
    return (
        <WebExtensionContext.Provider
            value={{ apiKey, configuration, discounts, contactUuid }}
        >
            {children}
        </WebExtensionContext.Provider>
    );
};

export const useWebExtension = (): WebExtensionContextType => {
    const context = useContext(WebExtensionContext) as WebExtensionContextType;
    if (context === null) {
        throw new Error("Only use 'useWebExtension' within a WebExtensionProvider");
    }
    return context;
};

export default WebExtensionProvider;
