import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

type LoadingContextType = {
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>> | null
}

const LoadingContext = createContext<LoadingContextType>({
    loading: false,
    setLoading: null,
});

export default function LoadingProvider({ children }: { children: ReactNode; }) {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
    );
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("Only use 'useLoading' within a LoadingProvider");
    }
    return context;
}
