import React from "react";

export default function ErrorHasOccurred(props: any) {

    return (
        <div>
            <div className="container">
                <div className="group">
                    <div style={{ height: "90vh", width: "100vh", margin: "auto", display: "flex" }}>
                        <div className={'relative flex h-full flex-col justify-between'}>
                            <div>(
                                <div className={'flex flex-col'}>
                                    <h2>
                                        Oops!
                                    </h2>
                                    <p className={'flex'}>
                                        Sorry, something went wrong
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
