import { GiftcardTransactionResource, RewardResource, VoucherResource } from "@leatcom/portal-sessions";
import * as Sentry from "@sentry/react";
import { getItemBySku, reverseRewardReception, reverseVoucherRedemption } from "../api";
import { ApplyTo } from "../types";

export const onClaimReward = (
    r: RewardResource,
    handler: (
        uuid: string,
        items: string,
        discount: string,
        applyTo: ApplyTo,
        onReverse: (message: string) => void,
        type: "VOUCHER" | "REWARD",
        onMultiItem?: () => void,
    ) => void,
    onMultiItem?: () => void,
    transactionUuid?: string,
    onError?: (message: string) => void
) => {
    const onReverse = async (message: string) => {
        onError?.(message); // `Can't map SKUs to identifiers. Reversing reward reception for reward: ${r.title}`
        transactionUuid && (await reverseRewardReception(transactionUuid));
    };

    handler(r.uuid , r.lsk_items as string, r.lsk_discount as string, r.lsk_apply_to as ApplyTo, onReverse, "REWARD", onMultiItem);
};

export const onRedeemVoucher = (
    v: VoucherResource,
    handler: (
        uuid: string,
        items: string,
        discount: string,
        applyTo: ApplyTo,
        onReverse: (message: string) => void,
        type: "VOUCHER" | "REWARD",
        onMultiItem?: () => void,
    ) => void,
    onMultiItem?: () => void,
    onError?: (message: string) => void
) => {
    const onReverse = async (message: string) => {
        onError?.(message); // `Can't map SKUs to identifiers. Reversing redemption of voucher with code: ${v.code}`
        await reverseVoucherRedemption(v.code);
    };

    handler(v.promotion.uuid, v.attributes.lsk_items, v.attributes.lsk_discount, v.attributes.lsk_apply_to, onReverse, "VOUCHER", onMultiItem);
};

export const onUseGiftcard = async (giftcardTransaction: GiftcardTransactionResource, amountInCents: number) => {
    if (giftcardTransaction.amount_in_cents <= 0) {
        window.pos_addPayment(Math.abs(giftcardTransaction.amount_in_cents), "LGC");
        if (Math.abs(giftcardTransaction.amount_in_cents) >= amountInCents) {
            window.pos_close();
        }
    } else {
        const res = await getItemBySku(posContext.locationId, "LEAT-PRODUCT-ITEM");
        const leatProductItem = res.data.item;

        if (!leatProductItem) {
            Sentry.captureMessage("No item with sku 'LEAT-PRODUCT-ITEM' configured.");
            return;
        }

        window.pos_addSpecialItemToCurrentAccount(leatProductItem.id.toString(), giftcardTransaction.amount_in_cents, "Giftcard");
    }
};
