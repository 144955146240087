import Modal from "../Modal/Modal";

export default function Error({ message } : { message: string }) {
    return (
        <Modal title={"Error"} isOpen={!!message} onClose={() => window.pos_close?.()}>
            <div className="min-w-72">
                <p className="text-red-600 text-lg">
                    {message}
                </p>
            </div>
        </Modal>
    );
}
