import { AxiosResponse } from "axios";
import { http } from "../HttpInstance";
import { LSKDiscount, LSKItem } from "../../types";

const endpoint = "/api/v1/integrations/ls-k-series/configuration";

export const getIntegrationConfiguration = async (
    businessLocationId: string,
): Promise<AxiosResponse<{ facing_mode: "user" | "environment"; business_profile_uuid: string }>> => {
    return http.instance.get(endpoint, {
        params: { business_location_id: businessLocationId },
    });
};

export const getLocationConfiguration = async (
    businessLocationId: string,
): Promise<AxiosResponse<{ items: Array<LSKItem>; discounts: Array<LSKDiscount> }>> => {
    return http.instance.get(`${endpoint}/location`, {
        params: { business_location_id: businessLocationId },
    });
};
