export enum ApplyTo {
    ORDER = "order",
    ITEMS = 'items',
}

export enum ItemType {
    ITEM = "ITEM",
    SUB_ITEM = "SUB_ITEM",
    GROUP = "GROUP",
    SEQUENCE = "SEQUENCE",
}
