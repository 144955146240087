import { StrictMode } from "react";
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import { ToastContainer } from "react-toastify";
import ErrorHasOccurred from "./ErrorHasOccurred";
import Root from "./Root";

import '../node_modules/@leatcom/portal-sessions/dist/style.css';
import './index.css';

Sentry.init({
    dsn: "https://82f18f750f3727196e761e1b305b5bbf@o80526.ingest.us.sentry.io/4507034416250880",
    enabled: process.env.NODE_ENV !== 'development',
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <StrictMode>
        <ErrorBoundary fallback={ErrorHasOccurred}>
            <Root/>
        </ErrorBoundary>

        <ToastContainer stacked/>
    </StrictMode>
);
