import { useEffect, useState } from "react";
import { useWebExtension } from "../../context";
import { useRetryUntilResolved, useTransactionHandler } from "../../hooks";
import { GetCurrentAccountData, Response } from "../../types";
import { Error, ItemSelect, Modal } from "../../components";
import { FacingMode, PortalSession, PortalType } from "@leatcom/portal-sessions";
import { onContactLink } from "../helpers";
import { onClaimReward, onRedeemVoucher, onUseGiftcard } from "../callbacks";

export default function Session() {
    const context = useWebExtension();
    const [amountInCents, setAmountInCents] = useState<number>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [scanCount, setScanCount] = useState<number>(0);

    const { handle, handleSelect, activeOptions, allOptions } = useTransactionHandler(setError);

    const resolved = useRetryUntilResolved(
        () => amountInCents !== undefined && !!context.configuration.business_profile_uuid.length,
    );

    useEffect(() => {
        window.pos_getCurrentAccount(({ data }: Response<GetCurrentAccountData, any>) =>
            setAmountInCents(data.totalAmount - data.paidAmount),
        );
    }, []);

    window.pos_onBarcodeScanEvent((response: any) => {
        const event = new CustomEvent("onCodeScan", { detail: { code: response.data.toString().replace(/\(QR\)/g, "") } });
        document.dispatchEvent(event);
        setScanCount(scanCount + 1);
    });

    return (
        resolved && (
            <>
                <PortalSession
                    host={process.env.REACT_APP_API_HOST}
                    type={PortalType.GENERIC}
                    apiKey={context.apiKey}
                    shopUuid={context.configuration.business_profile_uuid}
                    cameraFacingMode={context.configuration.facing_mode ?? FacingMode.USER}
                    contactUuid={context.contactUuid}
                    enableEmailIdentification
                    allowMultipleGiftcards
                    amountInCents={amountInCents}
                    onContactLink={onContactLink}
                    onClaimReward={(reward, transactionUuid) =>
                        onClaimReward(
                            reward,
                            handle,
                            () => setModalOpen(true),
                            transactionUuid,
                            (message: string) => setError(message),
                        )
                    }
                    onRedeemVoucher={(voucher) =>
                        onRedeemVoucher(
                            voucher,
                            handle,
                            () => setModalOpen(true),
                            (message: string) => setError(message),
                        )
                    }
                    onUseGiftcard={async (transaction) => await onUseGiftcard(transaction, amountInCents ?? 0)}
                />

                <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} canClose={false} title={"Items"}>
                    {allOptions && (
                        <ItemSelect
                            options={allOptions}
                            activeOptions={activeOptions}
                            onItemSelect={(option: {
                                label: string,
                                value: string
                            }) => handleSelect(option, () => setModalOpen(false))}
                        />
                    )}
                </Modal>

                <Error message={error}/>
            </>
        )
    );
}
