import { useEffect, useState } from "react";
import { FacingMode, PortalSession, PortalType } from "@leatcom/portal-sessions";
import { useWebExtension } from "../../context";
import { useRetryUntilResolved } from "../../hooks";
import { GetCurrentAccountData, Response } from "../../types";
import { onUseGiftcard } from "../callbacks";

export default function Session() {
    const context = useWebExtension();
    const [amountInCents, setAmountInCents] = useState<number>();
    const [scanCount, setScanCount] = useState<number>(0);

    const resolved = useRetryUntilResolved(() => amountInCents !== undefined && !!context.configuration.business_profile_uuid.length);

    useEffect(() => {
        window.pos_getCurrentAccount(({ data }: Response<GetCurrentAccountData, any>) => setAmountInCents(data.totalAmount - data.paidAmount));
    }, []);

    window.pos_onBarcodeScanEvent((response: any) => {
        const event = new CustomEvent("onCodeScan", { detail: { code: response.data.toString().replace(/\(QR\)/g, "") } });
        document.dispatchEvent(event);
        setScanCount(scanCount + 1);
    });

    return (
        resolved && (
            <>
                <PortalSession
                    host={process.env.REACT_APP_API_HOST}
                    type={PortalType.GIFTCARD}
                    apiKey={context.apiKey}
                    shopUuid={context.configuration.business_profile_uuid}
                    cameraFacingMode={context.configuration.facing_mode ?? FacingMode.USER}
                    allowMultipleGiftcards
                    amountInCents={amountInCents}
                    onUseGiftcard={async (transaction) => await onUseGiftcard(transaction, amountInCents ?? 0)}
                />
            </>
        )
    );
}
